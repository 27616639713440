
@import '../../assets/styles/helpers';

$heightWidth: 14px;
$circleHeightWidth: 8px;
$circleOffset: 2px;

.container {
  position: relative;
  box-sizing: border-box;

  > * {
    box-sizing: border-box;
  }

  > label {
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    padding-left: 18px;
    display: block;
  } 
  
  > input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    padding: 0;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    position: absolute;
    
    &:checked ~ .element::before {
      display: block;
    } 
  }
  
  > .element {
    background-color: white;
    border-radius: 100%;
    height: $heightWidth;
    width: $heightWidth;
    cursor: pointer;
    border: 1px solid $colorFormInputBorder;
    position: absolute;
    top: 4px;
    pointer-events: none;
    
    &:before {
      content: '';
      background-color: $colorFormInputBorder;
      height: $circleHeightWidth;
      width: $circleHeightWidth;
      position: absolute;
      top: $circleOffset;
      left: $circleOffset;
      border-radius: 100%;
      display: none;
    }
  }

  &.disabled {
    > label {
      opacity: .5;
    }

    > .element {
      background-color: $colorFormInputDisabledBackground;
    }
  }

  &.error {
    > label {
      color: red;
    }

    > .element {
      background-color: rgb(249, 154, 154);
      border: red 1px solid;
    }
  }

  #factoring0 label:hover::before {
    content: attr(for);
    font-family: Roboto, -apple-system, sans-serif;
    text-transform: capitalize;
    font-size: 11px;
    position: absolute;
    top: 170%;
    left: 0;
    right: 0;
    opacity: 0.75;
    background-color: #323232;
    color: #fff;  
    padding: 4px;
    border-radius: 3px;
    display: block;
  }
  
  /* =============================================
  * CENTERING, CONTAINER STYLING ETC || IGNORE
  =============================================== */
  
  h1 {
    font-weight: 300;
    color: #D9E7FD;
    text-align: center;
    margin-bottom: 50px;
  }
  
  #factoring0 {
    text-align: center;
    margin: 0 auto;
  }
  
  .container {
    margin: 0 auto;
  }
  
  html {
    height: 100%;
    font-family: Roboto, -apple-system, sans-serif;
  }
  
  body {
    background-color: #3367D6;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
  }
}