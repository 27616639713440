.left {
  float: left;
}

.right {
  float: right;
}

.container {
  //cant get flex or grid to work in this situation. Gotta use old fashoined floats
  overflow: auto;
  margin-bottom: 40px;
}
