.inputWrapper {
  margin-bottom: 10px;
  padding-top: 20px;

  >label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
  }
}

.loading {
  filter: blur(4px);
}

.mcDotInput{
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 10px 0;
  overflow: visible;
  padding: 5px 10px;
  color: var(--colorFormInputFontColor,#474747);
  border: 1px solid var(--colorFormInputBorder,#656565);
  border-radius: 3px;
  transition: border .3s;
  box-shadow: inset 0 0 0 0 transparent;
  outline: 0;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 34px;
}
