.container {
  >h2 {
    margin-bottom: 5px;
  }
}

.titleText {
  margin: 0;
  margin-bottom: 4px;
}

.questionWrapper {
  padding: 20px;
  padding-bottom: 20px;
  border: 1px solid black;
  margin-bottom: 20px;

}

.quickPayWrapper {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr;
  grid-gap: 15px;
}

.inputWrapper {
  display: flex;
}



@media
only screen 
    // and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

      .quickPayWrapper {
        display: block;
        margin: 10px
      }
      .quickPayWrapper div:nth-child(3n+3):not(:last-child){
        margin-bottom: 10px;
        padding-bottom: 10px;
        display: block;
        border-style: none none solid  none  ;
        border-width: 1px;
      }

    }