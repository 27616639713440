//bring in the normalizer stylesheet to base styles
@import './_normalize.scss';

@import './_variables.scss';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

// CSS variables. Make them CSS variables to keep the option open for theming
:root {
  --colorPrimaryBase: #073451;
  --colorPrimaryLight: #214E6B;
  --colorPrimaryDark: #001B38;
  --colorPrimaryFaded: #628397;
  --colorPrimaryTrans: rgba(7, 51, 80, 0.5);
  --colorSecondaryBase: #e3761c;
  --colorSecondaryLight: #FD9036;
  --colorSecondaryDark: #CA5D03;
  --colorSecondaryTrans: rgba(227, 118, 28, 0.5);
  --colorFormInputPlaceholder: rgba(44, 44, 44, .5);
  --colorFormInputFontColor: #474747;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  color: $colorFont;
  text-rendering: optimizelegibility;
  font-size: 16px;
  line-height: 1.2;
}

//make the app full screen so we can flexbox items
html {
  height: 100vh;
}

body {
  height: 100vh;

  //having trouble targeting #root. It is always the first child of body so we will target it that way
  >div {
    height: 100vh;
  }
}

h1 {
  font-size: 26px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: 480px) {
    font-size: 32px;
  }
}