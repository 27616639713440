.question {
  margin-bottom: 6px;
}

.radioWrapper {
  margin-left: 16px;
  margin-top: 20px;
}

.disclaimer {
  font-style: italic;
  margin: 0;
}

.type {
  font-weight: bold;
  text-decoration: underline;
}

.chargesHeading {
  font-weight: bold;
  font-style: italic;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

.divider {
  border-left: 2px dashed black;
  height: auto;
  margin-right: 30px;
}