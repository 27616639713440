.question {
  margin-bottom: 6px;
}

.radioWrapper {
  margin-left: 16px;
}

.disclaimer {
  font-style: italic;
  margin: 0;
}
