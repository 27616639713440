.container {
  padding-top: 30px;

  >p {
    line-height: 24px;
  }
}

.signed {
  margin-top: 40px;
}