.container {
  margin-bottom: 40px;
}

.agreementWrapper {
  padding: 20px;
  border: 1px solid black;
  margin-bottom: 20px;
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.signatureContainer {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.highlight {
  font-weight: 700;
}

.highlightRed {
  font-size: 16px;
  font-weight: 700;
  color: rgb(247, 48, 48);
  font-style: italic
}

.right {
  > label {
    margin-bottom: 4px;
  }

  > input {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 100px auto;

    > label {
      margin-top: 7px;
      margin-bottom: 0;
    }
  }
}

@media
only screen 
    and (max-device-width: 600px)  {

      .signatureContainer {
        display: block;
        margin-bottom: 25px;
      }
      .signatureContainer input{
        padding-bottom: 5px;
      }
      .signatureContainer label {
        margin-top: 25px;
      }

    }