.container {
  margin-bottom: 20px;
}

.infoText {
  margin-bottom: 10px;
}

.infoWrapper {
  border: 1px solid black;
  padding: 10px 20px;
  display: grid;
  font-size: 14px;
  line-height: 18px;

  >*:not(.title){
    margin-bottom: 10px;
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: 120px auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;

    >*:not(.title){
      margin-bottom: 0;
    }
  }
}

.title {
  font-weight: 700;
}
