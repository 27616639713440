.container {
  min-height: 54px;
}

.radioWrapper {
  display: grid;
  grid-template-columns: 60px 60px auto;
}

.errorText {
  color: red;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3px;
}