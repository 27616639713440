.container {
  background-color: var(--colorPrimaryBase);
  position: relative;
  z-index: 1;
}

.innerWrapper {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  padding-left: 20px;
  color: white;
  font-size: 24px;
}