.container {
  > label {
    margin-bottom: 4px;
  }

  > input {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 180px auto;

    > label {
      margin-top: 7px;
      margin-bottom: 0;
    }
  }
}
