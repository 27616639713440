// .container {
//   max-width: 450px;
// }

.listWrapper {
  @media screen and (min-width: 480px) {
    margin-left: 16px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;

  }
}

.errorMessage {
  color: red
}