.container {
  display: grid;
  align-items: start;
  
  @media screen and (min-width: 550px) {
    grid-column-gap: 20px;
    grid-template-columns: 150px 120px;
  }
}

.input {
  height: 36px;
}