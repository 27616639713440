.banner {
    // Position on screen
    position: fixed;
    right: -85px; //Hardcoded because the way the parent div is setup. This is tested and working on all screen sizes
    bottom: 30%;
    z-index: 1; //Bring to the VERY front of all elements

    // Styling
    background-color: var(--colorPrimaryBase);
    padding: 5px 10px 50px 10px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid var(--colorPrimaryDark);

    // Fancy transition to make it look nicer
    transition: all 0.2s ease-in-out;
    --scaler: 1; //CSS variable to hold scale
    transform: scale(var(--scaler)) rotate(-90deg);
    cursor: pointer;
}

// On hover, increase scale and slide out to the left slightly
.banner:hover {
    --scaler: 1.2; //Set scale to 1.2
}

.text {
    color: white;
    letter-spacing: 1px;
    user-select: none; //Disable text highlighting
}

// Mobile stuff
@media screen and (max-width: 480px) {
    .banner {
        transform: rotate(0deg);
        position: fixed;
        bottom: -45px; //Again hardcoded because of how our divs are setup but it is tested and working
        right: 10px;
        z-index: 1;
    }

    .banner:hover {
        --scaler: 1.2; //Set scale to 1.2
        right: 10px;
    }
}
