//sass variable map
$colorDanger: #EE1414;
$colorSuccess: #5cb85c;
$colorWhite: #fff;
$colorGray: #6C6D74;
$colorBlack: #000;
$colorFont: $colorBlack;

//form elements
$inputFontSize: 16px;
$inputBorder: 1px solid $colorGray;
$inputBorderRadius: 3px;
$inputPadding: 5px 10px;

$colorPrimaryBase: var(--colorPrimaryBase, #073451);
$colorPrimaryLight: var(--colorPrimaryLight, #214E6B);
$colorPrimaryDark: var(--colorPrimaryDark, #001B38);
$colorPrimaryFaded: var(--colorPrimaryFaded, #628397);
$colorPrimaryTrans: var(--colorPrimaryTrans, rgba(7, 51, 80, 0.5));
$colorSecondaryBase: var(--colorSecondaryBase, #e3761c);
$colorSecondaryLight: var(--colorSecondaryBase, #FD9036);
$colorSecondaryDark: var(--colorSecondaryDark, #CA5D03);
$colorSecondaryTrans: var(--colorSecondaryTrans, rgba(227, 118, 28, 0.5));
$colorFormInputPlaceholder: var(--colorFormInputPlaceholder, rgba(44, 44, 44, .5));
$colorFormInputFontColor: var(--colorFormInputFontColor, #474747);
$colorFormInputBorder: var(--colorFormInputBorder, #656565);
$colorFormInputDisabledBackground: var(--colorFormInputDisabledBackground, rgb(235, 235, 228));

//extends
@mixin input {
  color: var(--colorFormInputFontColor);
  border-radius: $inputBorderRadius;
  border: $inputBorder;
  font-size: $inputFontSize;
  line-height: $inputFontSize;
  padding: $inputPadding;
}